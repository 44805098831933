import axios from 'axios'
import utils from 'utils'

const api = axios.create({ baseURL: '/api' })

api.interceptors.request.use(function (config) {
  const token = utils.storage.getToken()
  if (token) {
    config.headers.authorization = 'Bearer ' + token
  }
  return config
})

const resources = {
  auth: {
    login(data) {
      return api.post('/auth/login', data)
    },
    verifySms(data) {
      return api.post('/auth/verify-sms', data)
    },
    resendToken(data) {
      return api.post('/auth/resend-token', data)
    },
  },
  users: {
    list(params) {
      return api.get('/users', { params })
    },
    show(id) {
      return api.get(`/users/${id}`)
    },
    me() {
      return api.get('/users/me')
    },
    scores(id) {
      return api.get(`/users/${id}/scores`)
    },
    wallet(id) {
      return api.get(`/users/${id}/wallet`)
    },
    actions(id) {
      return api.get(`/users/${id}/actions`)
    },
    upsertActions(id, data) {
      return api.put(`/users/${id}/actions`, data)
    },
    update(id, data) {
      return api.put(`/users/${id}`, data)
    },
  },
  orders: {
    list(params) {
      return api.get('/orders', { params })
    },
    listCommon(params) {
      return api.get('/orders/common', { params })
    },
    show(id) {
      return api.get(`/orders/${id}`)
    },
    payout(data) {
      return api.post('/orders/payout', data)
    },
    create(data) {
      return api.post('/admin/orders', data)
    },
  },
  wallet: {
    list() {
      return api.get('/wallet')
    },
  },
  offers: {
    list(params) {
      return api.get('/offers', { params })
    },
    active() {
      return api.get('/offers/active')
    },
    activePos(pos) {
      return api.get(`/admin/offers/${pos}/active`)
    },
  },
  sales: {
    list(params) {
      return api.get('/sales', { params })
    },
  },
  neutralizations: {
    list(params) {
      return api.get('/neutralizations', { params })
    },
  },
  sendTokens: {
    list(params) {
      return api.get('/send-tokens', { params })
    },
  },
  coupons: {
    campaign: {
      list(params) {
        return api.get('/coupon-campaigns', { params })
      },
      create(data) {
        return api.post('/coupon-campaigns', data)
      },
      getById(id) {
        return api.get(`/coupon-campaigns/${id}`)
      },
      update(id, data) {
        return api.put(`/coupon-campaigns/${id}`, data)
      },
    },
    code: {
      getCodesByCampaignId(id, data) {
        return api.get(`/coupon-campaigns/${id}/codes`, data)
      },
      createMany(id, data) {
        return api.post(`/coupon-campaigns/${id}/codes`, data)
      },
      update(id, data) {
        return api.put(`/coupon-codes/${id}`, data)
      },
    },
  },
  kyc: {
    bypassKycLevel(id) {
      return api.put(`/kyc_levels/${id}/bypass_kyc`)
    },
  },
  blockchain: {
    transfer(data) {
      return api.post('/blockchain/transfer', data)
    },
    burn(data) {
      return api.post('/blockchain/burn', data)
    },
    list({ page }) {
      return api.get(`/blockchain/transactions?page=${page}`)
    },
  },
}

export default resources
