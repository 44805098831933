import order from './order'
import sale from './sale'
import user from './user'
import neutralization from './neutralization'
import sendToken from './send-token'

export const status = {
  order,
  sale,
  user,
  neutralization,
  sendToken,
}
