export const ORDER_STATUS = [
  { id: 'successful_payment', color: 'success' },
  { id: 'successful_transfer', color: 'success' },
  { id: 'successful_offset', color: 'success' },
  { id: 'awaiting_offset', color: 'warning' },
  { id: 'awaiting_payment', color: 'warning' },
  { id: 'awaiting_payment_to_neutralize', color: 'warning' },
  { id: 'rejected_payment', color: 'error' },
  { id: 'expired_payment', color: 'error' },
]
