const mapStatus = {
  transferred_tokens: 'Token Transferido',
  transferring_tokens: 'Token em transferência',
}

const color = {
  transferred_tokens: 'success',
  transferring_tokens: 'warning',
}

const sendToken = { mapStatus, color }

export default sendToken
