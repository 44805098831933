import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MuiThemeProvider } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import { ApolloProvider } from '@apollo/client'

import ErrorBoundary from './components/ErrorBoundary'
import Router from './router'
import { theme } from './theme'
import { client } from './services/client'
import { AuthProvider } from './contexts/auth'

export default function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ErrorBoundary>
              <AuthProvider>
                <Router />
              </AuthProvider>
            </ErrorBoundary>
          </ThemeProvider>
        </ApolloProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  )
}
