import * as sentry from '@sentry/react'
import React from 'react'

export default class ErrorBoundary extends React.Component {
  constructor() {
    super()

    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    sentry.captureException(error)
    console.log(error)
  }

  render() {
    return (
      <>
        {this.props.children}
        {this.state.hasError && <p>Ops! Algo errado aconteceu</p>}
      </>
    )
  }
}
