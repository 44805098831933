const mapStatus = {
  active: 'Ativo',
  created: 'Criado',
  disabled: 'Desabilitado',
}

const color = {
  created: 'info',
  active: 'success',
  disabled: 'disabled',
}

const user = { mapStatus, color }

export default user
