import * as sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
import { createContext, useContext, useEffect, useState } from 'react'
import sdk from 'services/sdk'
import utils from '../utils'

const AuthContext = createContext()
const history = createBrowserHistory()

export function AuthProvider({ children }) {
  const [user, setUser] = useState()
  const [token, setToken] = useState(utils.storage.getToken())
  const [loading, setLoading] = useState(!!token)

  function logout() {
    setToken(null)
    utils.storage.removeToken()
    history.push(utils.routes.public.login)
  }

  function checkAdmin() {
    if (user && !['admin', 'super_admin'].includes(user.role)) return logout()
    return children
  }

  useEffect(() => {
    if (token) {
      utils.storage.setToken(token)
      sdk.users
        .me()
        .then(({ data }) => setUser(data))
        .catch(logout)
        .finally(() => setLoading(false))
    }
  }, [token])

  useEffect(() => {
    if (user) {
      sentry.setUser({
        id: user.id,
        email: user.email,
        username: `${user.first_name || ''} ${user.last_name || ''}`,
      })
    } else {
      sentry.setUser(null)
    }
  }, [user])

  return (
    <AuthContext.Provider value={{ token, setToken, user, logout }}>
      {loading ? null : checkAdmin()}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)

export default AuthContext
