const AUTH_KEY = 'token'

function setToken(token) {
  localStorage.setItem(AUTH_KEY, token)
}

function removeToken() {
  localStorage.removeItem(AUTH_KEY)
}

function getToken() {
  return localStorage.getItem(AUTH_KEY)
}

const storage = {
  setToken,
  removeToken,
  getToken,
}

export default storage
