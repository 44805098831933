const mapStatus = {
  successful_payment: 'Pagamento Confirmado',
  successful_offset: 'Compensado',
  awaiting_payment: 'Aguardando Pagamento',
  awaiting_payment_to_neutralize: 'Aguardando Pagamento para Compensar',
  awaiting_offset: 'Aguardando Compensação',
  successful_transfer: 'Créditos Transferidos',
  rejected_payment: 'Pagamento Rejeitado',
  expired_payment: 'Pagamento Expirado',
}

const color = {
  successful_payment: 'success',
  successful_offset: 'success',
  awaiting_payment: 'warning',
  awaiting_payment_to_neutralize: 'warning',
  awaiting_offset: 'warning',
  successful_transfer: 'success',
  rejected_payment: 'error',
  expired_payment: 'error',
}

const offsetStatus = {
  awaiting_payment_to_neutralize: 'awaiting_payment_to_neutralize',
  successful_payment: 'awaiting_offset',
  successful_transfer: 'successful_offset',
}

const isOffsetPayment = (status) => {
  return status.some((s) => s.status === 'awaiting_payment_to_neutralize')
}

const order = { mapStatus, color, offsetStatus, isOffsetPayment }

export default order
