import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import { useAuth } from './contexts/auth'
import { routes } from './utils/routes'
import LayoutDashboard from './layouts/dashboard'
import LayoutLogin from './layouts/login'

// public
const Login = lazy(() => import('./pages/public/login'))

// private
const Dashboard = lazy(() => import('./pages/private/dashboard'))
const Offers = lazy(() => import('./pages/private/offers'))
const Orders = lazy(() => import('./pages/private/orders'))
const OrdersShow = lazy(() => import('./pages/private/orders-show'))
const Neutralizations = lazy(() => import('./pages/private/neutralizations'))
const SendTokens = lazy(() => import('./pages/private/send-tokens'))
const Sales = lazy(() => import('./pages/private/sales'))
const Users = lazy(() => import('./pages/private/users'))
const UsersShow = lazy(() => import('./pages/private/users-show'))
const Wallet = lazy(() => import('./pages/private/wallet'))
const CouponCampaigns = lazy(() => import('./pages/private/coupon-campaigns'))
const CouponCampaignShow = lazy(() =>
  import('./pages/private/coupon-campaigns/ShowCampaigns')
)
const Transfers = lazy(() => import('./pages/private/transfers/TransferPage'))

function Router() {
  const auth = useAuth()

  return (
    <BrowserRouter>
      <Switch>
        <Suspense fallback={auth.token ? <LayoutDashboard /> : null}>
          {auth.token ? (
            <LayoutDashboard>
              <Route path={routes.private.dashboard} exact>
                <Dashboard />
              </Route>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Route path={routes.private.users.list} exact>
                  <Users />
                </Route>
                <Route path={routes.private.orders.list} exact>
                  <Orders />
                </Route>
                <Route path={routes.private.sales} exact>
                  <Sales />
                </Route>
                <Route path={routes.private.neutralizations} exact>
                  <Neutralizations />
                </Route>
                <Route path={routes.private.sendTokens} exact>
                  <SendTokens />
                </Route>
                <Route path={routes.private.couponCapaigns.list} exact>
                  <CouponCampaigns />
                </Route>
              </QueryParamProvider>
              <Route path={routes.private.users.show} exact>
                <UsersShow />
              </Route>
              <Route path={routes.private.orders.show} exact>
                <OrdersShow />
              </Route>
              <Route path={routes.private.offers} exact>
                <Offers />
              </Route>
              <Route path={routes.private.wallet} exact>
                <Wallet />
              </Route>
              <Route path={routes.private.couponCapaigns.show} exact>
                <CouponCampaignShow />
              </Route>
              <Route path={routes.private.transfers} exact>
                <Transfers />
              </Route>
            </LayoutDashboard>
          ) : (
            <LayoutLogin>
              <Route path={routes.public.login} exact>
                <Login />
              </Route>
              <Redirect to={routes.public.login} />
            </LayoutLogin>
          )}
        </Suspense>
      </Switch>
    </BrowserRouter>
  )
}

export default Router
