const statuses = {
  successful_payout: 'Vendido',
  success_transferred_tokens: 'Créditos transferidos',
  pending: 'Pendente',
  processing: 'Processando',
  waiting_payout_confirmation: 'Aguardando confirmação',
  transferring_tokens: 'Transferindo créditos',
  cancelled: 'Cancelado',
  cancelled_by_user: 'Cancelado pelo usuário',
}

const mapStatus = (status) => {
  return statuses[status]
}

const color = {
  successful_payout: 'success',
  success_transferred_tokens: 'success',
  pending: 'warning',
  processing: 'warning',
  waiting_payout_confirmation: 'warning',
  transferring_tokens: 'warning',
  cancelled: 'error',
  cancelled_by_user: 'error',
}

const sale = { mapStatus, color, statuses }

export default sale
