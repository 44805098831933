import { include } from 'named-urls'

export const routes = {
  private: {
    dashboard: '/',
    users: include('/users', {
      list: '',
      show: ':id',
    }),
    orders: include('/orders', {
      list: '',
      show: ':id',
    }),
    wallet: '/wallet',
    offers: '/offers',
    sales: '/sales',
    neutralizations: '/neutralizations',
    sendTokens: '/send-tokens',
    couponCapaigns: include('/coupon-campaigns', {
      list: '',
      show: ':id',
    }),
    transfers: '/transfer',
  },
  public: {
    login: '/',
    register: '/register',
  },
}
