const mapStatus = {
  neutralized: 'Neutralizado',
  neutralizing: 'Neutralizando',
}

const color = {
  neutralized: 'success',
  neutralizing: 'warning',
}

const neutralization = { mapStatus, color }

export default neutralization
