import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'

const environment = process.env.NODE_ENV

const allowedEnvs = ['staging', 'production'].includes(environment)

if (allowedEnvs) {
  const dsn =
    'https://0e4bc4515ff73a8e9ac64e2dde5aab96@o718510.ingest.sentry.io/4506264365301760'

  Sentry.init({
    dsn,
    environment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect
        ),
      }),
      new Sentry.Replay(),
    ],

    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
