import React from 'react'
import { NavLink } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import IconSend from '@material-ui/icons/Send'
import IconPerson from '@material-ui/icons/Person'
import IconOrder from '@material-ui/icons/LocalShipping'
import IconSales from '@material-ui/icons/Receipt'
import IconWhatshot from '@material-ui/icons/Whatshot'
import IconPrimaryOffer from '@material-ui/icons/EmojiEvents'
import IconDashboard from '@material-ui/icons/Dashboard'
import IconWallet from '@material-ui/icons/AccountBalanceWallet'
import IconPeople from '@material-ui/icons/People'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import grey from '@material-ui/core/colors/grey'

import { useAuth } from '../../contexts/auth'
import { routes } from '../../utils/routes'
import { useStyles } from './styles'

const menu = [
  {
    name: 'Dashboard',
    icon: IconDashboard,
    url: routes.private.dashboard,
  },
  {
    name: 'Usuários',
    icon: IconPerson,
    url: routes.private.users.list,
  },
  {
    name: 'Pedidos',
    icon: IconOrder,
    url: '/orders',
  },
  {
    name: 'Tokens Enviados',
    icon: IconSend,
    url: '/send-tokens',
  },
  {
    name: 'Neutralizações',
    icon: IconWhatshot,
    url: '/neutralizations',
  },
  {
    name: 'Vendas',
    icon: IconSales,
    url: '/sales',
  },
  {
    name: 'Oferta Primária',
    icon: IconPrimaryOffer,
    url: routes.private.offers,
  },
  {
    name: 'Campanhas e Cupons',
    icon: IconPeople,
    url: routes.private.couponCapaigns.list,
  },
  {
    name: 'Transferências',
    icon: CompareArrowsIcon,
    url: routes.private.transfers,
  },
  {
    name: 'Carteira',
    icon: IconWallet,
    url: routes.private.wallet,
  },
]

export default function DashboardLayout({ children }) {
  const classes = useStyles()
  const { logout } = useAuth()

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Backoffice
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="20"
            viewBox="0 0 100 20"
            fill="none"
          >
            <path
              d="M37.865 4.78234C35.8301 4.78234 34.2314 5.85134 34.2314 7.87577C34.2314 9.91585 35.8301 10.9683 37.865 10.9683H44.6208C46.589 10.9683 48.2718 9.88331 48.2718 7.87577C48.2718 5.86729 46.5725 4.78234 44.6385 4.78234H37.865ZM44.2513 0.264199C48.7598 0.264199 52.7297 2.77417 52.7297 7.85888C52.7297 12.912 48.7929 15.5033 44.2678 15.5033H38.218C33.6932 15.5033 29.7561 12.9279 29.7561 7.85888C29.7561 2.87209 33.7099 0.264199 38.218 0.264199H44.2513Z"
              fill="black"
            />
            <path
              d="M55.2302 17.4901C55.2302 18.8572 54.1105 19.9653 52.7298 19.9653C51.3491 19.9653 50.2294 18.8572 50.2294 17.4901C50.2294 16.1233 51.3491 15.0152 52.7298 15.0152C54.1105 15.0152 55.2302 16.1233 55.2302 17.4901Z"
              fill="black"
            />
            <path
              d="M25.7224 0.285896V19.5626H20.1793V8.09393L15.2581 19.5626H10.4668L5.51277 8.06421V19.5626H0V0.285896H6.74275L12.9271 14.8029L18.9809 0.285896H25.7224Z"
              fill="black"
            />
            <path
              d="M68.2015 20C60.9889 20 58.4711 17.4772 58.4711 12.8267H63.9488C64.0508 14.8024 64.9016 16.1094 68.065 16.1094C70.8893 16.1094 71.5694 15.0761 71.5694 14.1035C71.5694 10.3343 58.8452 13.0395 58.8452 6.23098C58.8452 2.82657 60.8526 0 67.6912 0C74.6317 0 76.5706 2.91761 76.673 6.86918H71.2294C71.1273 5.22799 70.5831 3.89057 67.5211 3.89057C64.9689 3.89057 64.3229 4.74151 64.3229 5.7445C64.3229 8.99686 76.9116 6.35236 76.9116 13.8297C76.9116 16.6869 75.04 20 68.2015 20Z"
              fill="black"
            />
            <path
              d="M91.2899 20C84.0772 20 81.5594 17.4772 81.5594 12.8267H87.0371C87.1392 14.8024 87.99 16.1094 91.1534 16.1094C93.9777 16.1094 94.6578 15.0761 94.6578 14.1035C94.6578 10.3343 81.9336 13.0395 81.9336 6.23098C81.9336 2.82657 83.941 0 90.7795 0C97.7201 0 99.659 2.91761 99.7614 6.86918H94.3178C94.2157 5.22799 93.6715 3.89057 90.6095 3.89057C88.0573 3.89057 87.4113 4.74151 87.4113 5.7445C87.4113 8.99686 100 6.35236 100 13.8297C100 16.6869 98.1284 20 91.2899 20Z"
              fill="black"
            />
          </svg>
        </div>
        <Divider />
        <List>
          {menu.map(({ name, icon: Icon, url }) => (
            <ListItem
              button
              exact={url === '/'}
              component={NavLink}
              activeStyle={{ backgroundColor: grey[200] }}
              to={url}
              key={name}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem button onClick={logout}>
            <ListItemText primary={'Sair'} />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}
