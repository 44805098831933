import { createTheme } from '@material-ui/core'
import { colors } from '@material-ui/core'

// import { typography } from './typography'
// import { palette } from './palette'

//@TODO add ./palette to createTheme()

export const theme = createTheme({
  palette: {
    primary: {
      main: '#29402B',
    },
    secondary: {
      main: '#ff6420',
    },
    disabled: {
      contrastText: '#FFFFFF',
      dark: colors.grey[900],
      main: colors.grey[600],
      light: colors.grey[100],
    },
  },
  // typography,
})
